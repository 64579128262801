/**
 * PinImages is meant to offer an accessible way to change the pin images for result pin
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
 class PinImages {
    /**
     * @param {Object} defaultPinConfig The configuration for the default pin
     * @param {Object} hoveredPinConfig The configuration for the hovered pin
     * @param {Object} selectedPinConfig The configuration for the selected pin
     */
    constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
      this.defaultPinConfig = defaultPinConfig;
      this.hoveredPinConfig = hoveredPinConfig;
      this.selectedPinConfig = selectedPinConfig;
    }
  
    /**
     * Generate standard theme pin given some parameters
     * @param {string} pin.backgroundColor Background color for the pin
     * @param {string} pin.strokeColor Stroke (border) color for the pin
     * @param {string} pin.labelColor Label (text) color for the pin
     * @param {number} pin.width The width of the pin
     * @param {number} pin.height The height of the pin
     * @param {string} pin.pinCount The index of the pin for the pin text
     * @return {Object} The SVG of the pin, and its width and height
     */
    generatePin ({
      backgroundColor = '#008555',
      strokeColor = 'black',
      labelColor = 'white',
      width = 20,
      height= 27,
      index = '',
      profile = ''
    } = {}) {
      const svg = `
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.5 0.5C28.5 0.5 32.0028 1.99535 34.5037 4.49628C37.0047 6.99721 38.5 10.5 38.5 14.5C38.5 18.8786 36.1031 22.2986 33.3805 26.2503C29.6663 31.6413 25.3168 37.9766 25.0187 48.7078C23.6832 37.9766 19.3337 31.6413 15.6195 26.2503C12.8969 22.2986 10.5 18.8786 10.5 14.5C10.5 10.5 11.9953 6.99721 14.4963 4.49628C16.9972 1.99535 20.5 0.5 24.5 0.5Z" fill="${backgroundColor}" stroke="black" stroke-opacity="0.5"/>
          <path d="M24.4251 21.5066C28.2497 21.5066 31.3502 18.4061 31.3502 14.5815C31.3502 10.7568 28.2497 7.65637 24.4251 7.65637C20.6005 7.65637 17.5 10.7568 17.5 14.5815C17.5 18.4061 20.6005 21.5066 24.4251 21.5066Z" fill="white"/>
        </svg>`;
      return { svg, width, height };
    };
  
    /**
     * Get the default pin image
     * @param {Number} pinCount The pin index number for the pin label
     * @param {Object} profile The profile data for the entity associated with the pin
     */
    getDefaultPin (index, profile) {
      return this.generatePin({
        backgroundColor: '#008555',
        strokeColor: this.defaultPinConfig.strokeColor,
        labelColor: this.defaultPinConfig.labelColor,
        width: 36,
        height: 36,
        index: '',
        profile: profile
      });
    }
  
    /**
     * Get the hovered pin image
     * @param {Number} pinCount The pin index number for the pin label
     * @param {Object} profile The profile data for the entity associated with the pin
     */
    getHoveredPin (index, profile) {
      return this.generatePin({
        backgroundColor: this.hoveredPinConfig.backgroundColor,
        strokeColor: this.hoveredPinConfig.strokeColor,
        labelColor: this.hoveredPinConfig.labelColor,
        width: 36,
        height: 36,
        index: '',
        profile: profile
      });
    }
  
    /**
     * Get the selected pin image
     * @param {Number} pinCount The pin index number for the pin label
     * @param {Object} profile The profile data for the entity associated with the pin
     */
    getSelectedPin (index, profile) {
      return this.generatePin({
        backgroundColor: "#D93D00",
        strokeColor: this.selectedPinConfig.strokeColor,
        labelColor: this.selectedPinConfig.labelColor,
        width: 50,
        height: 50,
        index: '',
        profile: profile
      });
    }
  }
  
  export { PinImages };